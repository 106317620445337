exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-books-[title]-js": () => import("./../../../src/pages/books/[title].js" /* webpackChunkName: "component---src-pages-books-[title]-js" */),
  "component---src-pages-books-js": () => import("./../../../src/pages/books.js" /* webpackChunkName: "component---src-pages-books-js" */),
  "component---src-pages-build-js": () => import("./../../../src/pages/build.js" /* webpackChunkName: "component---src-pages-build-js" */),
  "component---src-pages-design-[title]-js": () => import("./../../../src/pages/design/[title].js" /* webpackChunkName: "component---src-pages-design-[title]-js" */),
  "component---src-pages-design-js": () => import("./../../../src/pages/design.js" /* webpackChunkName: "component---src-pages-design-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-inspiration-js": () => import("./../../../src/pages/inspiration.js" /* webpackChunkName: "component---src-pages-inspiration-js" */),
  "component---src-pages-test-js": () => import("./../../../src/pages/test.js" /* webpackChunkName: "component---src-pages-test-js" */)
}

